import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import styled from 'styled-components'
import Layout from '@utils/layout'
import { graphql } from 'gatsby'
import { Footer } from '@lib/simba/_statics'
import Seo from '@components/Seo'
import { ARTICLE } from '@utils/get-schemas'
import getSeo from '@utils/get-seo'
import { HeaderInner } from '@lib/simba/header'
import Separator from '@lib/simba/separator'

const Whole = styled.div`
   
`
const LayoutArticle = styled.div`
    padding: ${({ theme }) => theme.paddingBox};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-width: ${({ theme }) => theme.maxWidth};
        margin: auto;
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
`

const Content = styled.div`
    padding: ${({ theme }) => theme.paddingBox};
    background: ${({ theme }) => theme.backgroundLight};
    color: ${({ theme }) => theme.article.color};
    h1, h2, h3, h4, h5 {
        color: ${({ theme }) => theme.colorText};
        padding: ${({ theme }) => theme.paddingBox};
        text-align: center;
    }
`

const Subtitle = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.article.color};
    opacity: 0.8;
    text-transform: uppercase;
`
const Title = styled.h1`
    color: ${({ theme }) => theme.colorText};
    text-align: center;
    padding: ${({ theme }) => theme.paddingBox};
    font-size: ${({ theme }) => theme.article.innerFontSize};
`

const Article = ({ data }) => (
    <Layout>
        <Seo seo={getSeo(data.article, ARTICLE)} />
        <HeaderInner img={data.article.cover && data.article.cover.asset.fluid} title={data.article.title} />
        <Whole>        
            <LayoutArticle>
                <Content>
                    <Subtitle>{data.article.categories.map(({ title }) => title).join(' | ')}</Subtitle>
                    <Title>
                        {data.article.title}
                    </Title>
                    <Separator />                    
                    <BlockContent blocks={data.article._rawContent} />
                </Content>
            </LayoutArticle>
        </Whole>

        <Footer />
    </Layout>
)

export default Article

export const query = graphql`
    query Mention($id: String) {
        article: sanityContenu(id: {eq: $id}) {
            title
            resume
            _rawContent(resolveReferences: {maxDepth: 5})
            categories {
                title
            }
            seo {
                title
                keywords
                description
            }
            cover {
                asset {
                    url
                    metadata {
                        dimensions {
                            height
                            width
                        }
                    }
                    fluid(maxWidth: 1280) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
        sidebar: allSanityContenu(filter: {
            categories: {elemMatch: {slug: {current: {eq: "nos-metiers"}}}}
            id: {ne: $id}
        }) {
            nodes {
                title
                slug {
                    current
                }
                categories {
                    title
                }
                cover {
                    asset {
                        fixed(width: 120) {
                            ...GatsbySanityImageFixed
                        }
                    }
                }
            }
        }
    }
`